<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Fiche source #{{source.id}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="success" size="sm" class="btn-icon mr-1" @click="save"><i class="icon-md fas fa-save"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-form-group label="Nom:">
            <b-form-input v-model="source.titre" type="text" required placeholder="Entre le nom"></b-form-input>
          </b-form-group>
          <div class="form-group">
            <label>Statut:</label>
            <select class="form-control" v-model="source.status">
              <option v-for="(s,i) in parametrage.status" :value="s.id" :key="i">{{s.titre}}</option>
            </select>
          </div>
          <b-form-group label="Prix unitaire:">
            <b-form-input v-model.number="source.prix" type="text" required placeholder="Entre le nom"></b-form-input>
          </b-form-group>
        </b-form>
        <b-row>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Champ lead</th>
                <th scope="col">Champ fichier</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <select class="form-control" v-model="modelTmp.lead_key">
                    <option value=""> Selectionnez le champ lead</option>
                    <option v-for="(f,i) in parametrage.fields" :value="f" :key="i">{{f}}</option>
                  </select>
                </th>
                <th>
                  <b-form-input v-model="modelTmp.file_key" type="text" placeholder="Copier/Coller le champ du fichier"></b-form-input>
                </th>
                <td>
                  <b-button variant="success" size="sm" class="btn-icon mr-1" @click="addModel"><i class="icon-md fas fa-plus"></i></b-button>
                </td>
              </tr>
              <tr v-for="(m,i) in source.model" :key="i">
                <th>
                  <select class="form-control" v-model="m.lead_key">
                    <option v-for="(f,i) in parametrage.fields" :value="f" :key="i">{{f}}</option>
                  </select>
                </th>
                <th>
                  <b-form-input v-model="m.file_key" type="text" required placeholder="Entre le nom"></b-form-input>
                </th>
                <td>
                  <b-button variant="danger" size="sm" class="btn-icon mr-1" @click="removeModel(i)"><i class="icon-md fas fa-trash"></i></b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      source:{
        id:null,
        titre:null,
        model:[],
        prix:0,
        status:null
      },
      modelTmp:{
        file_key:null,
        lead_key:""
      },
      parametrage:{
        status:[],
        fields:[]
      },
    };
  },
  components: {
  },
  computed: {
  },
  methods: {
    save() {
      this.$store.back.ajax('/parametrage/update?module=Source', this.source, (res) => {
        if(res.status === true){
          this.source = res.data;
          this.$bvToast.toast(`Vos modification sont sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    addModel(){
      if(this.modelTmp.lead_key === ""){
        return false;
      }
      this.source.model.push(this.modelTmp);
      this.modelTmp = {
        file_key:null,
        lead_key:null
      };
    },
    removeModel(i){
      this.source.model.splice(i, 1);
    }
  },
  watch: {
  },
  beforeMount(){
    let params = {
      modules: ["LeadStatus"]
    };
    this.$store.back.ajax('/parametrage/all', params, (res) => {
      if(res.status === true){
        this.parametrage.status = res.data['status'];
      }
    });
    this.$store.back.ajax('/lead/fields', null, (res) => {
      if(res.status === true){
        this.parametrage.fields = res.data;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);

    this.$store.back.ajax(`/parametrage/${this.$route.params.id}?module=Source`, null, (res) => {
      if(res.status === true){
        this.source = res.data.model;
      }
    });
  }
};
</script>
